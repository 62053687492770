import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import LayoutComponent from "../../components/LayoutComponent/LayoutComponent";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import "./szechenyi.scss";

const Szechenyi = (props) => {
  const { szechenyi_image, szechenyi_image_2, content } = props.pageContext.content;
  return (
    <div className="szechenyi-wrapper">
      <LayoutHeaderFooter
        header_background_color={"#811DFF"}
        header_secondary_color={"#6711FF"}
      >
        <div className="body-content">
          <LayoutComponent
            background={
              "radial-gradient(50% 50% at 50% 50%, #6711FF 0%, #811DFF 92.19%)"
            }
          >
            <img
              src={`${process.env.GATSBY_CMS_URL}${szechenyi_image.url}`}
              alt="szechenyi_plakat"
            />
            <div>
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={content}
              />
            </div>
          </LayoutComponent>
        </div>
      </LayoutHeaderFooter>
    </div>
  );
};

export default Szechenyi;
